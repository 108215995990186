import { apiEnvironment } from '../smoothr-web-app-core/environments/apiEnvironment';
import 'zone.js/dist/zone-error';

export const wohlfuhlerCustomerGroup = 'wohlfuhler';
export const customerGroup = wohlfuhlerCustomerGroup;

const supportEmail = {
	wohlfuhler: 'wohlfuhler@smoothr.de',
};
const firebaseConfig = {
	wohlfuhler: {
		apiKey: "AIzaSyATkp9rAPUFvhXmKC4x6MAjO5nEHU7qMCE",
		authDomain: "rohhappchen.firebaseapp.com",
		projectId: "rohhappchen",
		storageBucket: "rohhappchen.appspot.com",
		messagingSenderId: "668730761006",
		appId: "1:668730761006:web:3b5395f868f1d9ca6a24bf",
		measurementId: "G-MRTT6XM2BB"
	},
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyBoQjbSjrVanoPcDUh3cZS2LWWWzHeRxCY',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	countryList: ['de'],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup + '-dev',
	...apiEnvironment,
};


