import { apiEnvironment } from '../smoothr-web-app-core/environments/apiEnvironment';

export const wohlfuhlerCustomerGroup = 'wohlfuhler';
export const customerGroup = wohlfuhlerCustomerGroup;

const supportEmail = {
	wohlfuhler: 'wohlfuhler@smoothr.de',
};
const firebaseConfig = {
	wohlfuhler: {
		apiKey: "AIzaSyATkp9rAPUFvhXmKC4x6MAjO5nEHU7qMCE",
		authDomain: "rohhappchen.firebaseapp.com",
		projectId: "rohhappchen",
		storageBucket: "rohhappchen.appspot.com",
		messagingSenderId: "668730761006",
		appId: "1:668730761006:web:16c09fb5f69c92326a24bf",
		measurementId: "G-DHX4B2BZ8E"
	},
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyDPABTGaEg1-GhchYoLYxS_2LFt6lsJAXQ',
	baseUrl: 'https://api.smoothr.de',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	countryList: ['de'],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup,
	...apiEnvironment,
};
