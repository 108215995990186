import {TranslateService} from '@ngx-translate/core';
import {Component, OnInit} from '@angular/core';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import {environment} from '../../../environments/environment';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import {
	isVenueOpen,
	sleep,
	venueAcceptsOrders
} from '../../../smoothr-web-app-core/utils/utils';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import Address from '../../../smoothr-web-app-core/models/Address';
import {OrderType} from 'src/smoothr-web-app-core/enums/OrderType';

import {ScanQrModal} from 'src/app/components/scan-qr/scan-qr.component';
import {ModalController} from '@ionic/angular';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SelectTableModalComponent} from 'src/app/components/select-table-modal/select-table-modal.component';
import {ActivatedRoute, Router} from '@angular/router';
import {Api} from 'src/smoothr-web-app-core/api/api';
import {BehaviorSubject} from 'rxjs';
import {TimeUtils} from 'src/smoothr-web-app-core/utils/time-utils';
import moment from 'moment';
import {WorkingHoursModalComponent} from 'src/app/components/working-hours-modal/working-hours-modal.component';
import {MenuPage} from '../menu/menu.page';
import {HomePage} from '../home/home.page';

enum Language {
	GreatBritain = 'en',
	German = 'de'
}

@Component({
	selector: 'app-select-venue',
	templateUrl: './select-venue.page.tabilo.html',
	styleUrls: ['./select-venue.page.tabilo.scss']
})
export class SelectVenuePage extends RepositoryDirective implements OnInit {
	static url = 'select-venue';
	// static scanQr = 'scan-qr/:venueId/:tableId';
	environment = environment;
	languageEnum = Language;

	loading = false;
	preorderTypes = [PreorderType.INSIDE, PreorderType.TAKE_AWAY];
	active: any = {};
	loading$ = new BehaviorSubject(false);
	public relevantVenues: Venue[] = [];
	isVenueOpen = isVenueOpen;

	constructor(
		protected repository: RepositoryService,
		public translate: TranslateService,
		private modalCtrl: ModalController,
		private snackbarCtrl: MatSnackBar,
		private route: ActivatedRoute,
		private router: Router
	) {
		super(repository);
		this.loading$.next(false);
	}

	static async navigate(router: Router) {
		await router.navigateByUrl(SelectVenuePage.url);
	}

	ngOnInit() {
		super.ngOnInit();

		this.route.params.subscribe((params: any) => {
			if (params && params?.venueId && params?.tableId) {
				this.loadSelectedVenueWithTableId(params?.venueId, params?.tableId);
			} else if (params && params?.venueId) {
				this.loadSelectedVenue(params?.venueId);
			} else {
				this.locateAndShowStores(PreorderType.INSIDE);
			}
		});
	}
	async loadSelectedVenueWithTableId(venueId: string, tableId: string) {
		try {
			this.loading$.next(true);
			const venue = await this.repository.getVenue(venueId);
			const table = (await Api.getTable(this.venue._id, tableId)).data;
			const isOpenResturant = TimeUtils.doesHoursMatchNow(venue?.openingHours);
			if (!isOpenResturant) {
				this.loading$.next(false);
				this.snackbarCtrl.open(
					this.translate.instant('home_page.venue_closed'),
					null,
					{
						duration: 5000
					}
				);
				return;
			}
			if (venue && table) {
				this.relevantVenues = [venue];
				this.repository.venue.emit(venue);
				this.repository.createOrder(this.venue, null, PreorderType.INSIDE);
				this.order.tableNumber = table.number;
				this.order.table = table._id;
				this.repository.order.emit(this.order);
				sleep(200);
				this.loading$.next(false);
				MenuPage.navigate(this.router);
			}
		} catch (e) {
			this.loading$.next(false);
		}
	}
	async loadSelectedVenue(venueId: string) {
		this.loading$.next(true);
		const venue = await this.repository.getVenue(venueId);
		if (venue) {
			this.relevantVenues = [venue];
		}
		await this.selectVenue(venue);
		this.loading$.next(false);
	}
	ionViewDidEnter() {
		if (history.state.navigationId === 1) {
			this.repository.order.emit(null);
		}
		this.repository.verifiedOrder.emit(null);
	}

	async locateAndShowStores(preorderType: PreorderType) {
		this.loading$.next(true);
		const venues = await this.repository.getAllVenues();
		if (venues.length > 0) {
			this.relevantVenues = venues;
		}
		this.loading$.next(false);
	}

	async loadVenueAndCreateOrder(
		venue: Venue,
		address: Address,
		preorderType: PreorderType
	) {
		const loadedVenue = await this.repository.getVenue(venue._id);
		this.repository.createOrder(loadedVenue, address, preorderType);
		await MenuPage.navigate(this.router);
		this.loading$.next(false);
	}

	loadVenues() {
		for (const preorderType of this.preorderTypes) {
			this.active[preorderType] = false;
		}
		new Promise<void>(async (resolve, reject) => {
			try {
				const venues = await this.repository.getAllVenues();
				for (const venue of venues) {
					for (const preorderType of this.preorderTypes) {
						this.active[preorderType] =
							this.active[preorderType] ||
							venueAcceptsOrders(venue, preorderType);
					}
				}
				resolve();
			} catch (e) {
				reject(e);
			}
		}).finally(() => this.loading$.next(false));
	}
	async goToMenu(type: PreorderType) {
		this.locateAndShowStores(type);
	}
	changeLang(language: string) {
		this.translate.setDefaultLang(language);
		this.translate.use(language);
	}
	async loadVenueByQrCode(venueId: string, tableNumber: string) {
		try {
			this.loading$.next(true);
			const loadedVenue = await this.repository.getVenue(venueId);

			if (!loadedVenue) {
				console.log('No available venues');
				this.snackbarCtrl.open('Sorry, this shop doesn`t exist', null, {
					duration: 2000
				});
				this.loading$.next(false);

				return;
			}
			const isOpenResturant = TimeUtils.doesHoursMatchNow(
				loadedVenue?.openingHours
			);
			if (!isOpenResturant) {
				this.loading$.next(false);
				this.snackbarCtrl.open(
					this.translate.instant('home_page.venue_closed'),
					null,
					{
						duration: 5000
					}
				);
				return;
			}
			const table = (await Api.getTable(loadedVenue._id, tableNumber)).data;
			await sleep(100);
			if (loadedVenue && table) {
				this.repository.createOrder(loadedVenue, null, PreorderType.INSIDE);
				this.order.tableNumber = table.number;
				this.order.table = table._id;
				this.repository.order.emit(this.order);
				sleep(100);
				// await SelectTableModalComponent.show(this.modalCtrl, this.order);
				MenuPage.navigate(this.router);
			}
			this.loading$.next(false);
		} catch (e) {
			this.snackbarCtrl.open(
				this.translate.instant('home_page.scan_table_error'),
				null,
				{
					duration: 2000
				}
			);
			this.loading$.next(false);
		}
	}
	goBack() {
		HomePage.navigate(this.router);
	}
	isVenueOpenFunc(venue: Venue) {
		return this.isVenueOpen(venue)
			? this.translate.instant('venue_suggestion.open')
			: this.translate.instant('venue_suggestion.close');
	}

	async selectVenue(venue: Venue) {
		this.loading$.next(true);
		const isOpenResturant = TimeUtils.doesHoursMatchNow(venue?.openingHours);
		if (!isOpenResturant) {
			this.loading$.next(false);
			this.snackbarCtrl.open(
				this.translate.instant('home_page.venue_closed'),
				null,
				{
					duration: 5000
				}
			);
			return;
		}
		const loadedVenue = await this.repository.getVenue(venue._id);
		console.log(loadedVenue);
		await sleep(100);
		if (!loadedVenue) {
			this.loading$.next(false);
			this.snackbarCtrl.open(
				this.translate.instant('home_page.venue_closed'),
				null,
				{
					duration: 5000
				}
			);
			return;
		}
		this.repository.createOrder(loadedVenue, null, PreorderType.TAKE_AWAY);
		sleep(100);
		MenuPage.navigate(this.router);
		this.loading$.next(false);
	}
	async openInfoPage(venue: Venue) {
		await WorkingHoursModalComponent.show(
			this.modalCtrl,
			venue,
			PreorderType.INSIDE
		);
	}
}
